<!-- 招生系统基础配置 -- 中考普高控制线 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form :model="form" :rules="rules" ref="form" label-width="150rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="省份" prop="province">
        <el-select v-model="form.province" placeholder="请选择" @change="getCity">
          <el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市" prop="city">
        <el-select v-model="form.city" placeholder="请选择" @change="getArea">
          <el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择年份" prop="year">
        <el-select :popper-append-to-body="false" v-model="form.year" placeholder="请选择" class="select">
          <el-option v-for="item in yearList" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <h4 style="padding: 50rem 0 20rem 30rem">编辑各区/县中考普高控制线</h4>

      <div class="knowledge" v-for="(item, index) in form.sub">
        <div class="flex" style="background: #f4f4f4;padding: 20rem 40rem 20rem 0">
          <el-form-item label="选择区/县" label-width="100rem" style="margin-bottom: 0">
            <el-select :popper-append-to-body="true" v-model="item.area">
              <el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code" :disabled="item.disabled">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="分数线" label-width="100rem" style="margin-bottom: 0" :prop="'sub.'+index+'.score'" :rules="[
            {pattern:/^[\w\s,，.。!！\-;；+=\'\‘“\[\]【】\(\)（）]{0,10}$/,message:'请输入10字以内的数字，符号，字母'}
          ]">
            <el-input placeholder="请输入" maxlength="10" v-model="item.score"></el-input>
          </el-form-item>
        </div>
        <div class="flex" style="margin-left: 40rem">
          <el-button icon="el-icon-delete" circle="" @click="deleteKnowledge(index)" v-if="form.sub.length !== 1"></el-button>
          <el-button icon="el-icon-plus" circle="" @click="addKnowledge"></el-button>
        </div>
      </div>
    </el-form>
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data(){
    return{
      backType: 0,
      form:{
        province: '',
        city: '',
        year: '',
        sub: [{area: '', score: ''}]
      },
      rules:{
        province:[{required:true,message:"请选择省份"}],
        city:[{required:true,message:"请选择城市"}],
        year:[{required:true,message:"请选择年份"}],
      },
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      yearList: []
    }
  },
  created() {
    this.getYear()
    this.getProvince()
  },
  watch:{
    form:{
      deep: true,
      handler(val){
        let arr = val.sub.map(item => item.area)
        this.areaOptions.forEach(item => {
          item.disabled = arr.includes(item.address_code)
        })
      }
    }
  },
  methods:{
    //计算年份
    getYear(){
      let year = new Date().getFullYear()
      for (let i = 0; i < 6; i++) {
        this.yearList.push({label: year + '年', value: year})
        year++
      }
    },

    // 添加科目
    addKnowledge() {
      this.form.sub.push({area: '', score: ''})
    },

    //获取省份
    getProvince(){
      this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
        // console.log(res)
        this.provinceOptions = res.data.data
      })
    },

    //获取城市
    getCity(e){
      this.cityOptions = []
      this.form.city = ''
      this.areaOptions = []
      this.form.sub = [{area: '', score: ''}]
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.cityOptions = res.data.data
      })
    },

    //获取区/县
    getArea(e){
      this.areaOptions = []
      this.form.sub = [{area: '', score: ''}]
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        let data = res.data.data
        this.areaOptions = data.map(item => {
          return {...item, disabled: false}
        })
      })
    },

    // 删除科目
    deleteKnowledge(index) {
      this.form.sub.splice(index, 1)
    },

    // 提交数据
    submit(){
      this.$refs.form.validate((res,e,p)=>{
        if (res){
          let params = {...this.form}
          this.$_register.post("/api/recruit/score-line",params).then(res=>{
            this.$message({
              type: 'success',
              message: res.data.msg,
              onClose: () => {
                this.$store.commit("setPage",1);
                this.$router.back();
              }
            });
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.form{
  padding-top: 70rem;
}
::v-deep .el-form .el-input{
  min-width: 250rem;
}

.knowledge {
  padding-left: 60rem;
  margin-top: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }
  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
